import { Grid, Link } from "@mui/material";
import Section from "../../common/background/Section";
import CenterHeader from "../../common/text/CenterHeader";

function Partners({ config }) {
  return (
    <Section>
      <Grid item xs={12} paddingBottom={6} paddingTop={5}>
        <CenterHeader title={config.title} body={config.body} />
        <Grid xs={12} container>
          {config.organizations.map((organization) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              paddingTop={{ xs: 2, md: 0 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                href={organization.href}
                key={`about-partner-link-${organization.name}`}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
              >
                <img
                  src={organization.img}
                  alt={organization.name}
                  width="220px"
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Section>
  );
}

export default Partners;
