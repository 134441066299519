import { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";

import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";

import Highlight from "../../Audio/HighlightWrapper";

function HighlightScroll({
  filters,
  handleRemoveFilter,
  highlights,
  sectionId,
  isMapShowing,
}) {
  const [selectedHighlight, setSelectedHighlight] = useState(null);

  // for lazy loading
  const [displayIndex, setDisplayIndex] = useState(5);
  const [displayHighlights, setDisplayHighlights] = useState([]);

  const handleSelect = (hid) => {
    setSelectedHighlight(hid);
  };

  const handleWaypointEnter = (index) => {
    setDisplayIndex(
      Math.min(Math.max(displayIndex, index + 2), highlights.length)
    );
  };

  useEffect(() => {
    setDisplayHighlights(highlights.slice(0, displayIndex));
  }, [highlights, displayIndex]);

  return (
    <Grid
      id="highlight-scroll"
      style={{
        background: "#f3f3f3",
        height: "85vh",
        fontSize: "14px",
        overflow: "scroll",
      }}
    >
      <Grid
        container
        paddingLeft={{ xs: 4, md: 6 }}
        paddingRight={{ xs: 4, md: 3 }}
        paddingTop={7}
      >
        <Grid xs={isMapShowing ? 12 : 6} item>
          {highlights.length} highlights.
        </Grid>
      </Grid>
      <Grid marginX={{ xs: 3, md: 5 }} marginY={2} xs={12}>
        {filters.map((item) => (
          <Chip
            label={item.label}
            onDelete={() => handleRemoveFilter(item)}
            sx={{ marginRight: 1, marginBottom: 1 }}
          />
        ))}
      </Grid>
      <Grid
        container
        paddingX={{ xs: 4, md: 5 }}
        paddingTop={3}
        sx={{ width: "100%" }}
      >
        {displayHighlights.map((highlight, highlightIndex) => (
          <Waypoint
            key={`highlight-scroll-waypoint-${highlight.id}`}
            onEnter={() => handleWaypointEnter(highlightIndex)}
            topOffset={"33%"}
            bottomOffset={"10%"}
          >
            <Grid
              item
              xs={12}
              md={isMapShowing ? 12 : 6}
              paddingRight={{ xs: 0, md: isMapShowing ? 2 : 7.5 }}
              paddingBottom={8}
            >
              <Highlight
                sectionId={sectionId}
                highlight={highlight}
                onSelect={handleSelect}
                selectedHighlight={selectedHighlight}
                scrolling
              />
            </Grid>
          </Waypoint>
        ))}
      </Grid>
    </Grid>
  );
}

export default HighlightScroll;
