import { Grid, Box, Typography } from "@mui/material";
import { getSpeakerPath } from "../../../utils/utils.js";

function Profile({ profile }) {
  const xsDimension = "100px";
  const smDimension = "175px";
  const style = {
    width: { xs: xsDimension, sm: smDimension },
    height: { xs: xsDimension, sm: smDimension },
    borderRadius: "50%",
  };

  const file = `${getSpeakerPath(profile.name)}.jpg`;
  const src = `${process.env.PUBLIC_URL}/avatars/${file}`;

  return (
    <Grid item lg={3} md={4} xs={6}>
      <center>
        <Box component="img" sx={style} src={src} alt={profile.name} />
        <Box paddingTop={2}>
          <Typography variant="body1">
            <strong>{profile.name}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">{profile.description}</Typography>
        </Box>
      </center>
    </Grid>
  );
}

export default Profile;
