import React from "react";
import { useIdentityContext } from "react-netlify-identity";

import { Box } from "@mui/system";
import { Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { ORGANIZATION } from "../../config";
import { ArrowBack } from "@mui/icons-material";

const Login = () => {
  const { loginUser, signupUser, requestPasswordRecovery } =
    useIdentityContext();
  const formRef = React.useRef();
  const [msg, setMsg] = React.useState("");
  const [signupMsg, setSignupMsg] = React.useState("");
  const [step, setStep] = React.useState(0);

  const changeStep = (type) => {
    if (type === "increment") {
      const nextStep = Math.min(step + 1, 3);
      setStep(nextStep);
    }
    if (type === "decrement") {
      const nextStep = Math.max(step - 1, 0);
      setStep(nextStep);
    }
  };

  const resetPassword = () => {
    const email = formRef.current.email.value;
    if (!email) {
      setMsg("Email must be provided to reset password.");
    } else if (
      !(email.endsWith("@cortico.ai") || email.endsWith("@fmcs.gov"))
    ) {
      setMsg("Error: email must end with @fmcs.gov or @cortico.ai");
    } else {
      requestPasswordRecovery(email)
        .then(() => {
          setMsg(`Recovery link sent to ${email}`);
        })
        .catch((err) => console.error(err) || setMsg("Error: " + err.message));
    }
  };

  return (
    <Box
      minHeight="100vh"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#11304B",
      }}
    >
      <div style={{ width: "50%" }}>
        <Typography
          variant="h5"
          noWrap
          component="div"
          sx={{
            paddingRight: { xs: "0.75rem", sm: "3rem" },
            paddingLeft: ".5rem",
            height: "2.25rem",
            marginBottom: ".25rem",
            color: "#FFFFFF",
            float: "left",
          }}
        >
          Voices of FMCS
        </Typography>
        <a
          href={ORGANIZATION.homepage}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit", float: "right" }}
        >
          <img alt={ORGANIZATION.name} src={ORGANIZATION.small} />
        </a>
      </div>
      <Container
        style={{
          width: "50%",
          background: "#ebedef",
          borderRadius: "8px",
          padding: "1rem",
          minHeight: "175px",
        }}
      >
        {step !== 0 && (
          <Button onClick={() => changeStep("decrement")}>
            <ArrowBack />
          </Button>
        )}
        {step === 0 && (
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              const email = e.target.email.value;
              const password = e.target.password.value;
              loginUser(email, password, true)
                .then((user) => {
                  console.log("Success! Logged in", user);
                })
                .catch(
                  (err) => console.error(err) || setMsg("Error: " + err.message)
                );
            }}
          >
            <TextField
              required
              id="email"
              label="email"
              style={{ marginRight: "1rem" }}
            />
            <TextField
              required
              type="password"
              id="password"
              label="password"
            />
            <Button
              variant="contained"
              disableElevation
              color="primary"
              size={"large"}
              sx={{
                margin: "0.5rem",
                ":hover": {
                  color: "white",
                },
                marginLeft: "1rem",
              }}
              type="submit"
            >
              Log In
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              size={"large"}
              sx={{
                margin: "0.5rem",
                ":hover": {
                  color: "white",
                },
              }}
              onClick={() => changeStep("increment")}
            >
              Sign Up
            </Button>
            <div>
              <Button
                variant="text"
                disableElevation
                size={"large"}
                sx={{
                  margin: "0",
                  padding: "0",
                  color: "#548AC5",
                  ":hover": {
                    backgroundColor: "inherit",
                  },
                }}
                onClick={resetPassword}
              >
                Reset Password
              </Button>
              <Typography
                variant="h7"
                sx={{
                  color: "#11304B",
                }}
              >
                {msg && <pre>{msg}</pre>}
              </Typography>
            </div>
          </form>
        )}
        {step === 1 && (
          <Container>
            <Typography variant="h5">Terms And Conditions</Typography>
            <Typography variant="body1">
              By signing up to view this portal, I agree to the terms and
              conditions provided above.
            </Typography>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              size={"large"}
              sx={{
                margin: "0.5rem",
                ":hover": {
                  color: "white",
                },
                marginLeft: "1rem",
              }}
              onClick={() => changeStep("increment")}
            >
              Accept
            </Button>
          </Container>
        )}
        {step === 2 && (
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              const email = e.target.email.value;
              const password = e.target.password.value;

              // Check the email matches allowed emails
              if (
                !(email.endsWith("@cortico.ai") || email.endsWith("@fmcs.gov"))
              ) {
                setSignupMsg(
                  "Error: email must end with @fmcs.gov or @cortico.ai"
                );
              } else {
                signupUser(email, password)
                  .then((user) => {
                    console.log("Success! Signed up", user);
                    changeStep("increment");
                  })
                  .catch(
                    (err) =>
                      console.error(err) ||
                      setSignupMsg("Error: " + err.message)
                  );
              }
            }}
          >
            <TextField
              required
              id="email"
              label="email"
              style={{ marginRight: "1rem" }}
            />
            <TextField
              required
              type="password"
              id="password"
              label="password"
            />
            <Button
              variant="contained"
              disableElevation
              color="primary"
              size={"large"}
              sx={{
                margin: "0.5rem",
                ":hover": {
                  color: "white",
                },
                marginLeft: "1rem",
              }}
              type="submit"
            >
              Sign Up
            </Button>

            <Typography
              variant="h7"
              sx={{
                color: "#11304B",
              }}
            >
              {signupMsg && <pre>{signupMsg}</pre>}
            </Typography>
          </form>
        )}
        {step === 3 && (
          <>
            <Typography variant="h5">Sign Up Successful!</Typography>
            <Typography paragraph>
              Complete the signup process by verifying your account through the
              email that you received.
            </Typography>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Login;
