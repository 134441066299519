import React from "react";
import { Outlet } from "react-router-dom";
import Login from "./Login";

import { Box } from "@mui/system";
import NavBar from "../../components/Routing/NavBar";

import { ORGANIZATION } from "../../config";

import Topics from "../../assets/content/topics.json";
import { useIdentityContext } from "react-netlify-identity";

const AuthOverlay = () => {
  const { isConfirmedUser } = useIdentityContext();
  if (isConfirmedUser === false) {
    return <Login />;
  }
  return (
    <Box minHeight="100vh">
      <NavBar topics={Topics} />
      <Outlet />
      <footer
        style={{
          background: "#11304B",
          width: "100%",
          height: "5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href={ORGANIZATION.homepage}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img alt={ORGANIZATION.name} src={ORGANIZATION.small} />
        </a>
      </footer>
    </Box>
  );
};

export default AuthOverlay;
