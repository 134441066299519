import Participant from "../../../assets/landing-page/mosaic.png";
export const LANDING = {
  title:
    "7 FMCS staff facilitated 6 small group conversations with 40+ FMCS members about working at the agency.",
  description: [
    "<i>“In collaboration with the MIT Center for Constructive Communication (MIT CCC) and Cortico, we developed a pilot program that is a confluence of scientific method, technology and conversation. This platform has the potential to provide a next generation approach to delivering services to FMCS clients in our constantly evolving work environment.”</i> -Kevin Hawkins, Office of Strategy and Development",
  ],
};

// Supplying "background" field sets the background to the specified picture
export const HIGHLIGHT = {
  featured: {
    "What were the opportunities you experienced?": {
      featured: [5250630, 5249054, 5250341, 5250342],
      text: "",
      search: "/voices/search?prompt=Hopes",
    },
    "What were some of the challenges?": {
      featured: [5249063, 5249055, 5248948, 5250593],
      text: "",
      search: "/voices/search?prompt=Challenges",
    },
    "How did you experience these opportunities and challenges?": {
      featured: [5249112, 5249118, 5250352, 5250355],
      text: "",
      search: "/voices/search?prompt=Personal%20&%20Resonating%20Experiences",
    },
    "What are your hopes about the future of FMCS?": {
      featured: [5249035, 5249084, 5250371, 5250460],
      text: "",
      search: "/voices/search?prompt=FMCS%20Future",
    },
  },
};

export const TOPIC = {
  title:
    "Eight key insights with clear recommendations emerged from small group conversations with 40+ FMCS staff across tenures.",
  link: "/insights",
  linkLabel: "View Insights & Recommendations",
  body: [
    "In collaboration with the FMCS Office of Strategy & Development, this pilot program introduces MIT CCC and Cortico tools that provide the potential to support our agency’s work.<br/><br/>",
    "Our process and technology was built to elevate insights into FMCS organizational culture as perceived by staff across the agency. We invite you to explore the insights and recommendations shared by your colleagues throughout this interactive portal.<br/><br/>",
    "<b>Share your feedback as you explore.</b> How might this platform be practically applied to FMCS casework? Could this be part of the next big step in mediation technology?",
  ],
  teaser: {
    "Work-Life Balance":
      "In the course of adjusting to pandemic-era work, mediators discuss the complicated and sometimes surprising results of working from home.<br/><br/>“[I don’t know whether you would call it] an opportunity or a challenge to have back-to-back meetings. I’ve been on Zoom meetings and Team meetings at the same time,” says one mediator. <br/><br/>Listen to more staff from across the agency share their experiences with work-life balance.",
    "Remote Working Experience":
      "Everyone, everywhere, all the time. The signature experience of remote work for mediators is the remarkable number of connections mediators can make almost simultaneously.<br/><br/>One mediator shares their feelings about remote work: “I was like, ‘Oh, I'm so ready for this.’ All jazzed up. And I agree, I think this is a layering of, it's another tool, it's another opportunity.”<br/><br/>Listen to more staff from across the agency share their experiences with working remotely.",
    "Client Engagement":
      "Mediators adapt to the changing needs of their clients by offering flexibility, creating welcoming environments, relying on their tried and true skill sets.<br/><br/>“Yesterday, I had a meeting in Montana and had a meeting in Iowa, one in the morning and one in the afternoon. So you're able to serve your customers really well,” says one mediator.<br/><br/>Listen to more staff from across the agency share their experiences with client engagement.",
    Culture:
      "While mediators are clear-eyed about some of the institutional challenges facing their agency, the feeling of fellowship and sharing a sense of purpose permeates the culture of FMCS. <br/><br/>“[...] we do have different people onboarded differently and their experiences therefore are different, so [this conversation] helped me to continue to keep that in context,” shares one mediator, reflecting on the diversity of onboarding experiences at FMCS.<br/><br/>Listen to more staff from across the agency share their experiences with FMCS work culture.",
    Relationships:
      "Mediators take the care and feeding of their relationships seriously. Their connections emerge in a variety of ways and include the cultivation of client connections, teaming with colleagues across departments, and mentoring new mediators into the professional fold. <br/><br/>One mediator describes the virtual work environment as a relationship-building opportunity: “I think the virtual stuff has got us to meet more people, and that's an opportunity, and how much more fun and interesting and I think building for relationships it's going to be when we all actually get to be together in real person.”<br/><br/>Listen to more staff share their experience building relationships across the agency.",
    "Training + Development":
      "Mediators express great interest in skills training and the continued cultivation of their practice. Participants share their thoughts on professional development.<br/><br/>One mediator reflects on the importance of interpersonal support in their training experience: “It was, ‘Here's a half hour, let's figure out, make sure you can get connected,’ and it was, ‘Yay, I have an FMCS who's a cheerleader who's going to help me work through this and it's not going to be scary. And maybe it is, but I've got somebody there that I can fall back on.’”<br/><br/>Listen to more staff share their experience with training and development at FMCS.",
    "Intra-Organizational":
      "Mediators observe that if FMCS would seize the changing landscape in order to innovate, streamline, de-silo, and create stronger teams, then the result would be better services, and a stronger professional network.<br/><br/>“I think there's everybody's skill levels, knowledge levels and exchange of information has been a great plus, and we've kind of ignored the old EM thing of, don't talk to anybody and we do what makes sense logically,” shares one mediator.<br/><br/>Listen to more staff share their experience with intra-organizational connection and collaboration at FMCS.",
    Process:
      "Mediators share their thoughts, feelings, and insights on the experience of participating in these small group conversations.<br/><br/>One mediator reflects on what they appreciated most about their conversation experience: “I guess the thing that I most appreciate about it, well, two things, one, that the agency is trying something new and letting us be guinea pigs for it. But then also, my colleagues on here, I really appreciate what I've learned from each of you and about each of you.”<br/><br/>Listen to more staff reflect on their conversation experiences.",
  },
};

export const PARTICIPANT = {
  title: "Meet Our JA Youth Advisors",
  body: "100+ JA Youth Advisors across 45+ countries participated in the Youth Voices conversations as facilitators and conversation participants.",
  picture: Participant,
};

export const CTA = {
  title:
    "Did JA/INJAZ/YE change your life, too? <a className='jsx-link' href='https://gatheralumni.org' target='_blank'>Join our global alumni community of changemakers.</a>",
  link: "https://gatheralumni.org",
};

export const LOCATION = {
  title: "Six JA Regions",
  label: "JA Worldwide",
  link: "/voices/map-explorer",
  body: [
    "Reaching more than 12 million young people each year through nearly half a million teachers and business volunteers, JA Worldwide is one of few organizations with the scale, experience, and passion to build a brighter future for the next generation of innovators, entrepreneurs, and leaders.",
  ],
  descriptions: {
    "JA Africa": {
      title: "JA Africa",
      body: "JA Africa is the regional operating center for JA Worldwide in sub-Saharan Africa. Over the next decade, JA Africa aims to provide one million African youth with information and resources, and to build the self-confidence and self-reliance necessary for Africa’s youth and the subcontinent to succeed.",
      link: "/voices/map-explorer?location=JA%20Africa",
      label: "JA Africa",
    },
    "JA Americas": {
      title: "JA Americas",
      body: "JA Americas is the regional operating center for JA Worldwide in Canada, Latin America, and the Caribbean. JA Americas serves youth across a diverse group of countries through a mix of 30+ distinct programs organized around work readiness, financial literacy, and entrepreneurship.",
      link: "/voices/map-explorer?location=JA%20Americas",
      label: "JA Americas",
    },
    "JA Asia Pacific": {
      title: "JA Asia Pacific",
      body: "JA Asia Pacific is the regional operating center for JA Worldwide in Asia Pacific. Private and public sector support and on-the-ground delivery of JA programs through 18 JA local areas enable JA Asia Pacific to help young people develop skills for employment and contribute to a strong and capable workforce.",
      link: "/voices/map-explorer?location=JA%20Asia%20Pacific",
      label: "JA Asia Pacific",
    },
    "JA Europe": {
      title: "JA Europe",
      body: "JA Europe is the regional operating center for JA Worldwide across Europe. As the largest European provider of entrepreneurship education programs, JA Europe brings the public and private sectors together to provide youth with experiences that promote the skills, understanding, and perspective they need to succeed in a global economy.",
      link: "/voices/map-explorer?location=JA%20Europe",
      label: "JA Europe",
    },
    "INJAZ Al-Arab JA MENA": {
      title: "INJAZ Al-Arab JA MENA",
      body: "INJAZ Al-Arab JA MENA is the regional operating center for JA Worldwide across the Middle East and North Africa. INJAZ provides education and training in entrepreneurial skills and teaches programs to develop financial literacy and soft skills that equip young people to run their own businesses and find employment.",
      link: "/voices/map-explorer?location=INJAZ%20Al-Arab%20JA%20MENA",
      label: "INJAZ Al-Arab JA MENA",
    },
    "JA USA": {
      title: "JA USA",
      body: "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=JA%20USA",
      label: "JA USA",
    },
  },
};
