import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { HighlightPlayer } from "lvn-embed-api";

import Footer from "../Footer";
import Speaker from "../Speaker";
import PlayButton from "./PlayButton";
import Transcript from "../Transcript";
import TopicChips from "../TopicChips";
import { formatDuration, getSpeakerInfo } from "../../../utils/utils";
import { BRAND } from "../../../config";
import Speakers from "../../../assets/content/speakers.json";

function Medley({ highlightId, duration }) {
  const [minimalPlayer, setMinimalPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayTime, setDisplayTime] = useState(duration);

  const handlePlayerEnded = () => setIsPlaying(false);
  const handlePlayerTimeUpdate = (e) => {
    setDisplayTime(e.seconds);
  };
  const handlePlayerReady = (player) => {
    player.on("ended", handlePlayerEnded);
    player.on("timeupdate", handlePlayerTimeUpdate);
  };

  useEffect(() => {
    setMinimalPlayer(
      new HighlightPlayer({
        containerId: `medley-embed-minimal-${highlightId}`,
        highlightId: highlightId,
        highlightOptions: {
          type: "minimal",
          fontSize: "16px",
          scrolling: true,
          fontFamily: BRAND.headerFontFamily,
          fontWeight: "500",
          highlightColor: BRAND.defaultHighlightColor,
        },
        onReady: handlePlayerReady,
      })
    );
  }, [highlightId]);

  const handlePlayToggle = () => {
    if (minimalPlayer) {
      if (minimalPlayer.getIsPlaying()) {
        minimalPlayer.pause();
      } else {
        minimalPlayer.play();
      }
      setIsPlaying(minimalPlayer.isPlaying);
    }
  };

  return (
    <>
      <Card elevation={1} sx={{ width: "100%", borderRadius: "5px" }}>
        <CardContent sx={{ paddingRight: ".5rem", paddingTop: ".5rem" }}>
          <Grid
            container
            paddingLeft={2}
            paddingRight={2}
            paddingBottom={0.5}
            sx={{ alignItems: "center" }}
          >
            <Grid item sm={1.5}>
              <PlayButton
                duration={`${formatDuration(displayTime)}`}
                isPlaying={isPlaying}
                onClick={handlePlayToggle}
              />
            </Grid>
            <Grid item sm={9.5}>
              <Typography variant="h6" color="text.primary">
                Voices of FMCS Medley
              </Typography>
            </Grid>
            <Grid item sm={1}>
              <Typography variant="caption" color="text.secondary">
                {formatDuration(duration)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={12}>
            <Transcript sectionId={"medley"} hid={highlightId} extend />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default Medley;
