import { HighlightAlt } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import InfoModalButton from "../../common/buttons/InfoModalButton";

import { InfoOutlined } from "@mui/icons-material";

export default function ConversationHelpModal() {
  const instructions = [
    {
      text: "Hover or click on the themes to see the connections.",
      icon: <HighlightAlt />,
    },
    {
      text: "Click on a highlight box to hear the highlight. ",
      icon: <HighlightAlt />,
    },
    {
      text: "Click on a conversation listen to its highlights.",
      icon: <HighlightAlt />,
    },
  ];
  return (
    <InfoModalButton
      title={"Voices By Conversation"}
      icon={<InfoOutlined sx={{ fontSize: "20px" }} />}
      buttonLabel={"Start Exploring"}
      defaultOpen
    >
      <p>
        This is a data visualization exploration of all 6 conversations. Explore
        the most common themes and listen to the actual conversation.
      </p>

      <Grid container spacing={3} paddingTop={1}>
        {instructions.map(({ text, icon }) => (
          <>
            <Grid item sm={1} xs={2}>
              {icon}
            </Grid>
            <Grid item sm={11} xs={10}>
              {text}
            </Grid>
          </>
        ))}
      </Grid>
    </InfoModalButton>
  );
}
