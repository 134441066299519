import { useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import JsxParser from "react-jsx-parser";

import TopicsViz from "./TopicsViz";
import TopicCard from "../../TopicView/TopicCard";
import InfoCard from "../../common/cards/InfoCard";

import { extractTopic } from "./PackedCircles/d3-utils.js";

function TopicsOverviewContainer({ topics, config, margin }) {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);

  const handleSelection = (topic, subTopic) => {
    if (!subTopic) {
      setSelectedTopic(topic);
      setSelectedSubTopic(null);
    } else {
      setSelectedTopic(null);
      setSelectedSubTopic(null);
    }
  };

  const handleClose = () => {
    setSelectedTopic(null);
    setSelectedSubTopic(null);
  };

  const topicId = selectedTopic ? extractTopic(selectedTopic) : null;
  const topicInfo = topics.find((topic) => topic.id === topicId);

  return (
    <Grid container sx={{ backgroundColor: "white" }}>
      <Grid item xs={12} md={6} paddingTop={{ xs: 3, md: 0 }} paddingBottom={5}>
        <center>
          <TopicsViz
            themeMetadata={topics}
            selected={selectedTopic}
            section={selectedSubTopic}
            onSelection={handleSelection}
            onClose={handleClose}
            margin={margin}
          />
        </center>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box paddingTop={6} paddingBottom={8} paddingX={{ xs: 3, sm: 6 }}>
          {topicId && (
            <TopicCard
              topicInfo={topicInfo}
              topicId={topicId}
              teaserHtml={config.teaser[topicInfo.label]}
              transparent
            />
          )}
          {!topicId && (
            <InfoCard
              title={config.title}
              link={config.link}
              linkLabel={config.linkLabel}
              transparent
              // light
            >
              {config.body.map((paragraph, idx) => (
                <JsxParser key={`${config.title}-${idx}`} jsx={paragraph} />
              ))}
            </InfoCard>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default TopicsOverviewContainer;
