import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ArrowDownward } from "@mui/icons-material";
import JsxParser from "react-jsx-parser";

import { TEXTURE } from "../../../config";

function SplitBanner({ title, body, picture, texture, light, large, arrow }) {
  return (
    <Grid container>
      <Grid
        item
        lg={5}
        md={6}
        paddingX={{ xs: 5, sm: 8 }}
        paddingTop={10}
        paddingBottom={4}
        sx={{
          color: light ? "white" : "primary.main",
          backgroundColor: light ? "primary.dark" : "primary.light",
          background: texture ? `url(${TEXTURE})` : "primary.dark",
          backgroundSize: "cover",
        }}
      >
        <Typography variant="h4" paddingBottom={3}>
          {title}
        </Typography>
        {body &&
          body.map((text, idx) => (
            <Typography variant="h6" sx={{ fontWeight: 550, margin: "1rem 0" }}>
              <JsxParser key={`${title}-${idx}`} jsx={text} />
            </Typography>
          ))}
        {arrow && (
          <Typography sx={{ paddingTop: "2rem", marginBottom: "6rem" }}>
            <ArrowDownward />
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={7}
        md={6}
        sx={{
          display: { xs: "none", md: "block" },
          background: `url(${picture})`,
          backgroundSize: "cover",
          minHeight: large ? "65vh" : "50vh",
        }}
      ></Grid>
    </Grid>
  );
}

export default SplitBanner;
