import { Grid } from "@mui/material";

import StyledCallout from "../../common/callouts/StyledCallout";
import CenterHeader from "../../common/text/CenterHeader";
import ProfileGrid from "./ProfileGrid";

function StaffCallout({ config }) {
  return (
    <>
      <Grid paddingBottom={4}>
        <StyledCallout texture light>
          <CenterHeader title={config.title} body={config.body} />
          <ProfileGrid config={config.people} />
        </StyledCallout>
      </Grid>
    </>
  );
}

export default StaffCallout;
