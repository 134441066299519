import Grid from "@mui/material/Grid";

import HighlightScroll from "./HighlightScroll";

function ListView({ filters, handleRemoveFilter, highlights }) {
  return (
    <Grid item lg={12} display={{ xs: "block" }}>
      <HighlightScroll
        filters={filters}
        handleRemoveFilter={handleRemoveFilter}
        highlights={highlights}
        sectionId="full"
        isMapShowing={false}
      />
    </Grid>
  );
}

export default ListView;
