import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Stack, Container } from "@mui/material";
import DropdownMenu from "../../common/menus/DropdownMenu";

import Sticky from "react-sticky-el";

const JumpLink = styled("a")((props) => ({
  color: props.fontColor,
  textDecoration: "none",
  fontSize: "14px",
}));

function ProcessNav({
  steps,
  onClick,
  onChange,
  currSection,
  primaryColor,
  secondaryColor,
}) {
  const chipData = steps.map((step) => ({
    key: step.link,
    label: step.label,
  }));

  return (
    <Sticky>
      <Grid
        container
        item
        xs={12}
        sx={{
          paddingY: "1rem",
          backgroundColor: primaryColor ?? "primary.dark",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            spacing={5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            {chipData.map((data, ind) => (
              <JumpLink
                key={data.key}
                href={`#screen-${data.key}`}
                onClick={() => onClick(data.label)}
                fontColor={
                  currSection === data.label
                    ? "white"
                    : secondaryColor ?? "whitesmoke"
                }
                sx={{
                  fontWeight: currSection === data.label ? "700" : "500",
                }}
              >
                <div
                  style={{
                    border: "1px solid white",
                    borderRadius: "50%",
                    display: "inline-block",
                    width: "21px",
                    textAlign: "center",
                    marginRight: "0.5rem",
                  }}
                >
                  {ind + 1}
                </div>
                {data.label}
              </JumpLink>
            ))}
          </Stack>
          <Grid
            paddingX={{ xs: 3, sm: 6 }}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <DropdownMenu
              items={steps}
              selected={currSection}
              label="Current Section"
              handleChange={onChange}
            />
          </Grid>
        </Container>
      </Grid>
    </Sticky>
  );
}

export default ProcessNav;
