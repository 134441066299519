import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { HighlightPlayer } from "lvn-embed-api";

import Footer from "../Footer";
import Speaker from "../Speaker";
import PlayButton from "./PlayButton";
import Transcript from "../Transcript";
import TopicChips from "../TopicChips";
import { formatDuration, getSpeakerInfo } from "../../../utils/utils";
import { BRAND } from "../../../config";
import Speakers from "../../../assets/content/speakers.json";

function Highlight({
  sectionId,
  highlight,
  onSelect,
  selectedHighlight,
  extend,
  scrolling,
  light,
}) {
  const [minimalPlayer, setMinimalPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayTime, setDisplayTime] = useState(highlight.duration);

  const handlePlayerEnded = () => setIsPlaying(false);
  const handlePlayerTimeUpdate = (e) => {
    setDisplayTime(e.seconds);
  };
  const handlePlayerReady = (player) => {
    player.on("ended", handlePlayerEnded);
    player.on("timeupdate", handlePlayerTimeUpdate);
  };

  useEffect(() => {
    setMinimalPlayer(
      new HighlightPlayer({
        containerId: `${sectionId}-embed-minimal-${highlight.id}`,
        highlightId: highlight.id,
        highlightOptions: {
          type: "minimal",
          fontSize: "16px",
          scrolling: scrolling,
          fontFamily: BRAND.headerFontFamily,
          fontWeight: "500",
          highlightColor: BRAND.defaultHighlightColor,
          secret: highlight.secret,
        },
        onReady: handlePlayerReady,
      })
    );
  }, [sectionId, highlight, scrolling]);

  useEffect(() => {
    if (selectedHighlight !== highlight.id) {
      if (minimalPlayer) {
        minimalPlayer.pause();
        setIsPlaying(false);
      }
    }
  }, [selectedHighlight]);

  const handlePlayToggle = () => {
    if (minimalPlayer) {
      if (minimalPlayer.getIsPlaying()) {
        minimalPlayer.pause();
      } else {
        minimalPlayer.play();
      }
      setIsPlaying(minimalPlayer.isPlaying);

      if (onSelect) onSelect(highlight.id);
    }
  };

  const demographicList =
    highlight.speaker_demographics[BRAND.footerDemographic].split(".");

  return (
    <>
      <Card elevation={1} sx={{ width: "100%", borderRadius: "5px" }}>
        <CardContent sx={{ paddingRight: ".5rem", paddingTop: ".5rem" }}>
          <Grid
            container
            paddingLeft={2}
            paddingRight={2}
            paddingBottom={0.5}
            sx={{ alignItems: "center" }}
          >
            <Grid item sm={1.5}>
              <PlayButton
                duration={`${formatDuration(displayTime)}`}
                isPlaying={isPlaying}
                onClick={handlePlayToggle}
              />
            </Grid>
            <Grid item sm={9.5}>
              <Speaker
                speaker={highlight.speaker_name}
                primary={getSpeakerInfo(
                  BRAND.primaryDemographic,
                  Speakers,
                  highlight
                )}
                secondary={getSpeakerInfo(
                  BRAND.secondaryDemographic,
                  Speakers,
                  highlight
                )}
              />
            </Grid>
            <Grid item sm={1}>
              <Typography variant="caption" color="text.secondary">
                {formatDuration(highlight.duration)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={12}>
            <Transcript
              sectionId={sectionId}
              hid={highlight.id}
              extend={extend}
            />
          </Grid>
        </CardContent>
        <Footer
          extraInfo={`${BRAND.footerDemographicPrefix} ${demographicList[1]}`}
        />
      </Card>
      <TopicChips tags={highlight.tags} light={light} />
    </>
  );
}

export default Highlight;
