// Pictures for each section
import Participants from "../../../assets/landing-page/mosaic.png";

// Partner Logos
import FMCS from "../../../assets/partners/FMCS-logo.png";
import Cortico from "../../../assets/partners/cortico-logo.png";
import CCC from "../../../assets/partners/ccc-logo.png";

export const STAFF = {
  title: "Meet Our FMCS Facilitators",
  body: "FMCS Facilitators, all of whom are Meeting Tech Staff, guided each of the six conversations collected for this effort:",
  people: [
    {
      name: "Kathy Hall",
    },
    {
      name: "Brenda Pryor",
    },
    {
      name: "Ines Delgado-Alberto",
    },
    {
      name: "Ligia Velazquez",
    },
    {
      name: "Jimmy Valentine",
    },
    {
      name: "Tom Olson",
    },
  ],
};

export const ADMIN = {
  title: "Meet the Sensemakers",
  body: "Additional staff from the Office of Strategy & Development engaged in every step of the process - from conversation guide development to sensemaking to drafting narrative text for the portal.",
  people: [
    {
      name: "Kevin Hawkins",
    },
    {
      name: "Tom Melancon",
    },
  ],
};

export const CTA = {
  title: "Join Gather Today!",
  link: "https://gatheralumni.org",
  body: "Were you apart of JA, Junior Achievement, Young Enterprise, INJAZ, Impulsa, or any of the dozens of ways that JA is translated around the world? If so, you’re eligible to <a className='jsx-link' target='_blank' href='https://gatheralumni.org'>join Gather</a>, the JA alumni community, where doing good is just part of doing business.",
};

export const PARTICIPANTS = {
  title: "Meet Our JA Youth Advisors",
  body: "Pictured and named throughout this portal are the 100+ Youth Advisors, alumni from 45+ countries who shared their experiences in 25+ small group conversations, contributing their voices to strengthen the JA strategic plan and share a powerful message about JA values and direction.",
  picture: Participants,
};

export const PARTNERS = {
  title: "Our Partners",
  body: "Additional guidance and support for this project was provided by the FCMS leadership team, MIT CCC and Cortico.",
  organizations: [
    {
      name: "FMCS",
      img: FMCS,
      href: "https://www.fmcs.gov/",
    },
    {
      name: "Cortico",
      img: Cortico,
      href: "https://cortico.ai/",
    },
    {
      name: "MIT CCC",
      img: CCC,
      href: "https://www.ccc.mit.edu/",
    },
  ],
};
