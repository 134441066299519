import { Routes, Route } from "react-router-dom";

import Landing from "./components/Landing/Landing";
import MapViewContainer from "./components/VoicesView/MapView/MapViewContainer";
import TopicViewContainer from "./components/TopicView/TopicViewContainer";
import ConversationViewContainer from "./components/VoicesView/ConversationView/ConversationViewContainer";
import Insights from "./components/TopicView/Insights";
import Voices from "./components/VoicesView/Voices";
import About from "./components/About/About";

import Highlights from "./assets/content/highlights.json";
import Topics from "./assets/content/topics.json";
import { IdentityContextProvider } from "react-netlify-identity";
import AuthOverlay from "./components/Auth/AuthOverlay";
import Process from "./components/About/Process/Process";
import Team from "./components/About/Team/Team";

function App() {
  const url = "https://sunny-souffle-855b22.netlify.app/";
  return (
    <IdentityContextProvider url={url}>
      <Routes>
        <Route path="/" element={<AuthOverlay />}>
          <Route
            index
            element={<Landing topics={Topics} highlights={Highlights} />}
          />
          <Route path="/insights" element={<Insights topics={Topics} />} />
          <Route path="insights">
            <Route
              path=":id"
              element={
                <TopicViewContainer topics={Topics} highlights={Highlights} />
              }
            />
          </Route>
          <Route path="/voices" element={<Voices topics={Topics} />} />
          <Route
            path="voices/conversation-library"
            element={<ConversationViewContainer highlights={Highlights} />}
          />
          <Route
            path="voices/search"
            element={<MapViewContainer highlights={Highlights} />}
          />
          <Route path="about" element={<About />} />
          <Route path="about/process" element={<Process />} />
          <Route path="about/team" element={<Team />} />
        </Route>
      </Routes>
    </IdentityContextProvider>
  );
}

export default App;
