import { Typography, Box } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";

import Banner from "./Banner";
import BreadcrumbLinks from "./BreadcrumbLinks";

import { TEXTURE } from "../../../config";

function StyledBanner({
  title,
  body,
  picture,
  navigation,
  fontColor,
  texture,
  light,
  large,
  arrow,
  content,
  center,
}) {
  let sx = {
    color: fontColor ?? (light ? "white" : "primary.dark"),
    backgroundColor: light ? "primary.dark" : "primary.light",
    minHeight: "40vh",
    textAlign: center ? "center" : "default",
  };

  if (picture || texture) {
    sx = {
      ...sx,
      background: picture
        ? `url(${picture})`
        : texture
        ? `url(${TEXTURE})`
        : "unset",
      backgroundSize: "cover",
      minHeight: picture ? (large ? "60vh" : "55vh") : "40vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    };
  }

  return (
    <Banner sx={sx}>
      <Box>
        {navigation && (
          <Box>
            <BreadcrumbLinks navigation={navigation} color="inherit" />
          </Box>
        )}
        <Typography
          variant="h4"
          sx={{
            paddingBottom: "1.5rem",
          }}
        >
          {title}
        </Typography>
        {body && (
          <Typography variant="h6" sx={{ fontWeight: 550 }}>
            {body}
          </Typography>
        )}
        {content &&
          content.map((text) => (
            <Typography variant="h6" sx={{ fontWeight: 550, margin: "1rem 0" }}>
              {text}
            </Typography>
          ))}
        {arrow && (
          <Typography sx={{ paddingTop: "2rem", marginBottom: "6rem" }}>
            <ArrowDownward />
          </Typography>
        )}
      </Box>
    </Banner>
  );
}

export default StyledBanner;
