export function combineSnippetsWithSameSpeaker(entities) {
  const combinedSnippets = [];
  let lastSnippet;
  Object.values(entities.snippets).forEach((snippet) => {
    if (lastSnippet) {
      if (lastSnippet.speaker_id === snippet.speaker_id) {
        lastSnippet.audio_end_offset = snippet.audio_end_offset;
        lastSnippet.duration += snippet.duration;
      } else {
        combinedSnippets.push(lastSnippet);
        lastSnippet = { ...snippet };
      }
    } else {
      lastSnippet = { ...snippet };
    }
  });
  if (lastSnippet) {
    combinedSnippets.push(lastSnippet);
    lastSnippet = undefined;
  }

  return combinedSnippets;
}

export function tagToTitle(tag) {
  const tagUnderscore = tag.split(" ");
  let newTag = tagUnderscore
    .map((bit) => bit[0].toUpperCase() + bit.slice(1))
    .join(" ");
  const tagDash = newTag.split("-");
  newTag = tagDash.map((bit) => bit[0].toUpperCase() + bit.slice(1)).join("-");
  return newTag;
}

export function tagToClassName(tag) {
  return tag
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("-");
}
