import Step1 from "../../../assets/about/Our Process_1.png";
import Step2 from "../../../assets/about/Our Process_2.png";
import Step3 from "../../../assets/about/Our Process_3.png";

import Listening from "../../../assets/about/lvn.png";
import Analyzing from "../../../assets/about/insights.png";
import Synthesizing from "../../../assets/about/results.png";

export const INTRODUCTION = {
  title: "Voices of FMCS: How does this all work?",
  body: [
    "40+ FMCS staff were invited to join six small group conversations. We used tools and methods developed by the MIT Center for Constructive Communication and Cortico to highlight, analyze, and share recommendations and insights from these conversations.",
    "Listen to a few FMCS mediators reflect on their conversation experiences in the audio medley below:",
  ],
  medley: { id: 5251737, duration: 121 },
};

export const STEPS = [
  {
    label: "Facilitated Conversations",
    link: "facilitated-conversations",
    picture: Step1,
    body: [
      "We start exploring this space by inviting 40+ FMCS staff across a range of departments and tenures to join recorded small-group conversations with 4-6 participants. Conversations were facilitated by FMCS staff who attended an orientation led by MIT CCC/Cortico. During these conversations, FMCS staff members shared their personal experiences with organizational culture at FMCS, listened to the stories of others, and reflected on the connections, tensions, visions, and commonalities that emerged in their groups.",
      "Once a conversation had taken place, the audio recording was uploaded to Cortico’s Local Voices Network (LVN) platform, where the recording is transcribed and prepared for the sensemaking process. The recordings are in a protected collection only accessible to the Voices of FMCS project team.",
      "Ιn these conversations, we invited staff to share their stories and experiences in four areas:",
    ],
    sections: [
      {
        label: "Opportunities & Challenges",
        text: "What were the opportunities you experienced during this transition? What were some of the challenges?",
      },
      {
        label: "Personal & Resonating Experiences",
        text: "What personal experiences can help us better understand these opportunities and challenges? Which experiences of others resonated with you during the conversation?",
      },
      {
        label: "Future Visions",
        text: "What are your hopes and dreams about the future of FMCS and your role in that future?",
      },
      {
        label: "Takeaways",
        text: "What are you taking away from this conversation?",
      },
    ],
  },
  {
    label: "Sensemaking",
    section_label: "Step",
    link: "sensemaking",
    picture: Step2,
    body: [
      "After the conversations are facilitated and recorded the work of deep listening and human-led analysis begins. The conversations are uploaded onto our platform and become part of a collection. The sensemaking team at FMCS, Cortico, and the MIT Center for Constructive Communication actively engage with the six conversations to analyze, make meaning, and thematically organize the stories and experiences that were shared during the conversations.",
    ],
    sections: [
      {
        label: "Listening",
        picture: Listening,
        text: "We start by listening deeply to all conversations and immerse ourselves into the conversation collection. As we listen, we systematically identify and surface the themes that participants shared in the facilitated conversations.",
      },
      {
        label: "Analyzing",
        picture: Analyzing,
        text: "We collaboratively work together with the FMCS project team and built on each other’s knowledge, expertise, and skills to learn from one another and share nuanced insights into the experiences we are listening to. Our guiding questions were:",
        list: [
          "How might we analyze the rich and complex stories and experiences of the FMCS staff and make sense of those data?",
          "How can we uplift how the lived experiences shared in these conversations reflect the mission, the objectives, and the values of FMCS?",
        ],
        bullet: true,
      },
      {
        label: "Synthesizing",
        picture: Synthesizing,
        text: "Using tools and methods developed at the MIT Center for Constructive Communication, we identified themes and patterns in the conversations and mapped related experiences to eight thematic categories:",
        list: [
          "Work-Life Balance",
          "Remote Working Experience",
          "Client Engagement",
          "Culture",
          "Relationships",
          "Training + Development",
          "Intra-Organizational",
          "Process",
        ],
        bullet: true,
        small: true,
      },
    ],
  },
  {
    label: "Conversation Portal",
    link: "conversation-portal",
    picture: Step3,
    body: [
      "The conversation portal is an interactive, public-facing platform to explore and listen to the stories and experiences of FMCS staff in a thematically organized way. Experiences and stories can be explored by theme and agency tenure. After the analysis, we provide context for each theme and subtheme by summarizing what we heard in the conversations and translating themes into patterns.",
      "We weave into the thematic summaries representative highlights of the conversations to provide more nuanced insights into each theme and lift up participants’ voices who reflect on their lived experiences. FMCS staff who are provided with access can use the portal to listen and better understand the hopes, concerns, and experiences of their colleagues.",
      "Interested in learning more about our approach? Contact us at hello@cortico.ai.",
    ],
  },
];
