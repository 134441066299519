import { Grid, Typography } from "@mui/material";
import StyledBanner from "../../common/banners/StyledBanner";
import ProcessStep from "./ProcessStep";
import Section from "../../common/background/Section";

import ProcessImage from "../../../assets/about/process.jpg";

import Introduction from "./Introduction";

import { INTRODUCTION, STEPS } from "./config";
import { Waypoint } from "react-waypoint";

import { useEffect, useState } from "react";
import ProcessNav from "./ProcessNav";

const Process = () => {
  const [currSection, setCurrSection] = useState("Facilitated Conversations");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (section) => {
    setCurrSection(section);
    const stepInfo = STEPS.find((step) => step.label === section);
    const element = document.querySelector(`#screen-${stepInfo.link}`);
    const topPos =
      element.getBoundingClientRect().top + window.pageYOffset - 80;

    window.scrollTo({
      top: topPos,
      behavior: "smooth",
    });
  };

  const handleEnterWaypoint = (section) => {
    console.log(section);
    setCurrSection(section);
  };

  const getSection = (ind, sections) => {
    switch (ind) {
      case 0:
        return (
          <Grid container marginLeft={"-10px"} marginTop={5}>
            {sections.map((section) => {
              return (
                <Grid xs={12} md={6} sx={{ padding: "10px" }} item>
                  <div
                    style={{
                      background: "#ebedef",
                      textAlign: "center",
                      padding: "1rem",
                      height: "150px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ marginBottom: "1rem" }}
                      color="text.primary"
                    >
                      {section.label}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "1rem" }}
                    >
                      {section.text}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        );
      case 1:
        return sections.map((section, idx) => (
          <div style={{ margin: "3rem 0" }}>
            <Typography variant="body2">{`Step ${idx + 1}`}</Typography>
            <Typography variant="h6" style={{ marginBottom: "1rem" }}>
              {section.label}
            </Typography>
            <Grid container style={{ margin: "auto" }}>
              <Grid item xs={12} sm={6}>
                <img src={section.picture} alt="" width="75%" />
              </Grid>
              <Grid item xs={12} sm={6}>
                {section.text}
              </Grid>
            </Grid>
          </div>
        ));

      default:
        return <></>;
    }
  };

  return (
    <>
      <StyledBanner title={"Our Process"} picture={ProcessImage} light center />
      <ProcessNav
        steps={STEPS}
        onClick={handleClick}
        onChange={(event) => handleClick(event.target.value)}
        currSection={currSection}
      />
      <Introduction config={INTRODUCTION} />
      <Grid>
        {STEPS.map((step, idx) => (
          <>
            <Waypoint
              onEnter={() => handleEnterWaypoint(step.label)}
              topOffset="30%"
              bottomOffset="60%"
            >
              <div>
                <Section
                  sx={{
                    margin: "0",
                    padding: "5rem 0",
                    background: idx !== 1 ? "#FFF" : "",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    id={`screen-${step.link}`}
                    className="jump-link"
                  >
                    <ProcessStep
                      step={idx + 1}
                      title={step.label}
                      imageSrc={step.picture}
                    >
                      {step.body.map((paragraph) => (
                        <p>{paragraph}</p>
                      ))}
                      {step.list && (
                        <Typography
                          variant="body1"
                          paddingTop={4}
                          sx={{
                            fontStyle: "italic",
                          }}
                        >
                          <ul>
                            {step.list.map((item) => (
                              <li>{item}</li>
                            ))}
                          </ul>
                        </Typography>
                      )}
                    </ProcessStep>
                  </Grid>
                  {getSection(idx, step.sections)}
                </Section>
              </div>
            </Waypoint>
          </>
        ))}
      </Grid>
    </>
  );
};

export default Process;
