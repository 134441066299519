import { Grid, Typography } from "@mui/material";
import Medley from "../../Audio/Plain/Medley";
import StyledCallout from "../../common/callouts/StyledCallout";

function Introduction({ config }) {
  return (
    <StyledCallout texture light>
      <Grid container padding={3}>
        <Grid item md={12} paddingLeft={{ md: 3 }}>
          <Typography variant="h6" color="white" marginBottom={2}>
            {config.title}
          </Typography>
        </Grid>
        <Grid item md={12} paddingLeft={{ md: 3 }}>
          {config.body &&
            config.body.map((text) => (
              <Typography paragraph color="white" marginBottom={2}>
                {text}
              </Typography>
            ))}
        </Grid>
        <Grid item md={12} paddingLeft={{ md: 3 }}>
          <Medley
            highlightId={config.medley.id}
            duration={config.medley.duration}
          />
        </Grid>
      </Grid>
    </StyledCallout>
  );
}

export default Introduction;
