import { Grid, Typography } from "@mui/material";

const ProcessStep = ({ children, imageSrc, title }) => {
  return (
    <Grid container>
      <Grid item sm={12} md={10}>
        <Typography variant="h5" color="primary.dark">
          {title}
        </Typography>
        <Typography variant="body1">{children}</Typography>
      </Grid>
      {/* <img src={imageSrc} alt="" style={{ height: "125px" }} /> */}
    </Grid>
  );
};

export default ProcessStep;
