import { useEffect, useRef, useMemo } from "react";
import PackedCirclesViz from "./PackedCircles/PackedCirclesViz";
import VizData from "../../../assets/content/packed_circles_data.json";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

// Base dimensions for viewbox
const DEFAULT_WIDTH = 450;
const DEFAULT_HEIGHT = 450;

const DEFAULT_VERTICAL_PADDING = 10; // Between edge of viz and edge of SVG
const SVG = PackedCirclesViz.styled();

function ThemeViz({
  themeMetadata, // Theme colors
  selected, // Selected theme/subtheme/chord data (must have id property)
  section,
  onSelection = () => {},
  onClose = () => {},
  // Base width for drawing the viz (NOT actual width of svg)
  viewBoxWidth = DEFAULT_WIDTH,
  // Base height for drawing the viz (NOT actual height of svg)
  viewBoxHeight = DEFAULT_HEIGHT,
  // Padding between edge of svg and viz
  verticalPadding = DEFAULT_VERTICAL_PADDING,
  margin,
}) {
  const svgRef = useRef(null);

  let instruction = (
    <center>
      <Typography variant="caption" color="text.secondary" lineHeight={0.1}>
        The circle size reflects the number of highlights for a theme.
        <br />
        Click on a theme to learn more.
      </Typography>
    </center>
  );
  if (selected) {
    instruction = (
      <Button onClick={onClose}>
        <Typography variant="caption">
          <CloseIcon
            sx={{
              fontSize: "14px",
              paddingRight: "0.5rem",
              verticalAlign: "middle",
            }}
          />
          Back to Insights
        </Typography>
      </Button>
    );
  }

  const viz = useMemo(() => {
    return new PackedCirclesViz(
      svgRef,
      viewBoxWidth,
      viewBoxHeight - 2 * verticalPadding,
      { animDuration: 250 }
    );
  }, [viewBoxWidth, viewBoxHeight, verticalPadding]);

  useEffect(() => {
    viz.setUp(0, verticalPadding);

    return () => {
      viz.tearDown();
    };
  }, [viz, verticalPadding]);

  useEffect(() => {
    viz.update(
      VizData.filter((item) => !item.tag_title.includes("*") && item.subTopic), // Remove structural codes and no subtopic
      themeMetadata,
      selected,
      section
    );
    viz.registerHandlers(selected, onSelection);

    return () => {
      viz.deregisterHandlers();
    };
  }, [viz, themeMetadata, selected, section, onSelection]);

  return (
    <Grid paddingX={4} paddingTop={1} paddingBottom={3}>
      <Grid item>
        <SVG
          ref={svgRef}
          preserveAspectRatio="xMidYMid meet"
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
          id="infographic"
        />
      </Grid>
      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        {instruction}
      </Grid>
    </Grid>
  );
}

export default ThemeViz;
