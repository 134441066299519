import Logo from "./assets/logos/logo.png";
import LogoSmall from "./assets/logos/logo_small_text.svg";
import Texture from "./assets/textures/JA-Texture-Dark-Chalk.png";

export const ORGANIZATION = {
  name: "FMCS",
  homepage: "https://www.fmcs.gov/",
  logo: Logo,
  small: LogoSmall,
  embed: Logo,
  linkBack: true,
};

// label is used when the map is referenced
export const MAP = {
  label: "JA Region",
  demographicId: "ja region",
};

export const BRAND = {
  headerFontFamily: "Montserrat",
  defaultHighlightColor: "#CBECEE",
  primaryDemographic: "country of residence",
  secondaryDemographic: "secondary country of residence",
  footerDemographic: "tenure",
  footerDemographicPrefix: "FMCS Tenure:",
};

export const THEME = {
  palette: {
    primary: {
      main: "#11304B",
      dark: "#11304B",
      light: "#548AC5",
    },
    secondary: {
      main: "#ebedef",
      dark: "#00A0AF",
      light: "#00C0CA",
    },
  },
  typography: {
    h4: {
      fontFamily: BRAND.headerFontFamily,
      fontWeight: 800,
      fontSize: 32,
      paddingBottom: "1rem",
      letterSpacing: "0.25px",
    },
    h5: {
      fontFamily: BRAND.headerFontFamily,
      fontWeight: 650,
      fontSize: 28,
      paddingBottom: "1rem",
    },
    h6: {
      fontFamily: BRAND.headerFontFamily,
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "0.15px",
    },
    body1: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 16,
      letterSpacing: "0.15px",
    },
    body2: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 16,
      letterSpacing: "0.15px",
    },
    link1: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0.15px",
    },
    link2: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0.1px",
    },
    caption: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 12,
      letterSpacing: "0.4px",
    },
    subtitle1: {
      fontFamily: BRAND.headerFontFamily,
    },
    subtitle2: {
      fontFamily: BRAND.headerFontFamily,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: BRAND.headerFontFamily,
          fontWeight: 700,
          textTransform: "capitalize",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: BRAND.headerFontFamily,
          textTransform: "capitalize",
        },
      },
    },
  },
};

export const TEXTURE = Texture;
