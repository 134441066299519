import { useState, useEffect } from "react";
import { get } from "lodash";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import InfoModalButton from "../../common/buttons/InfoModalButton";
import SectionHeader from "../../common/banners/SectionHeader";
import MultipleCheckFilter from "../../common/filters/MultipleCheckFilter";

import { Tune } from "@mui/icons-material";

import ListView from "./ListView";
import {
  useQueryArg,
  MAP_QUERY_ARGS,
  getParentTagFromTagString,
} from "../../../utils/utils";
import { FILTERS } from "../config/config";

const getMatchingFilter = (prompt) => {
  if (!prompt) {
    return [];
  }
  const match = FILTERS.data.Prompts.find(
    (filter) => prompt.toUpperCase() === filter.label.toUpperCase()
  );
  return match ? [match] : [];
};

function MapViewContainer({ highlights }) {
  const prompt = useQueryArg(MAP_QUERY_ARGS.prompt);
  const [filteredHighlights, setFilteredHighlights] = useState(highlights);
  const [filters, setFilters] = useState(getMatchingFilter(prompt));

  useEffect(() => {
    document.getElementById("highlight-scroll").scrollTop = 0;
  }, [filteredHighlights]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleremoveFilter = (item) => {
    setFilters(filters.filter((x) => x !== item));
  };

  useEffect(() => {
    let filtered = highlights;

    if (filters.length > 0) {
      for (var filter of filters) {
        const key = filter.key;
        const type = filter.type;
        const match = filter.match;
        filtered = filtered.filter((highlight) => {
          if (type === "list") {
            return get(highlight, key).some((item) => item.includes(match));
          }
          if (type === "string") {
            return get(highlight, key)?.includes(match);
          }
          return false;
        });
      }
    }
    setFilteredHighlights(filtered);
  }, [highlights, filters]);

  return (
    <Grid>
      <Grid container>
        <SectionHeader
          title={`Voices of FMCS`}
          info={
            <InfoModalButton
              title="Filters"
              icon={<Tune sx={{ fontSize: "20px" }} />}
              buttonLabel="Close"
            >
              {FILTERS.filter_order.map((filterName) => {
                const items = FILTERS.data[filterName];
                return (
                  <div key={filterName}>
                    <Box sx={{ display: { xs: "block", sm: "none" } }}>
                      <MultipleCheckFilter
                        items={items}
                        defaultName={filterName}
                        selectedItems={filters}
                        setSelectedItems={setFilters}
                        singleColumn
                      />
                    </Box>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      <MultipleCheckFilter
                        items={items}
                        defaultName={filterName}
                        selectedItems={filters}
                        setSelectedItems={setFilters}
                      />
                    </Box>
                  </div>
                );
              })}
            </InfoModalButton>
          }
        />
        <ListView
          filters={filters}
          handleRemoveFilter={handleremoveFilter}
          highlights={filteredHighlights}
        />
      </Grid>
    </Grid>
  );
}

export default MapViewContainer;
