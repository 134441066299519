import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import Highlight from "../../Audio/HighlightWrapper";
import Carousel from "./Carousel";

export default function HighlightCarousel({ highlights, indexes, id }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedHighlight, setSelectedHighlight] = useState(null);

  useEffect(() => {
    setActiveIndex(0);
  }, [highlights]);

  const getHighlightList = () => {
    let indexedHighlights = [];
    for (var index of indexes) {
      indexedHighlights.push(
        highlights.find((highlight) => highlight.id === index)
      );
    }

    return indexedHighlights.map((highlight) => {
      if (!highlight) return;

      return (
        <Grid
          item
          paddingX={{ md: 3 }}
          key={`highlight-carousel-${highlight.id}`}
        >
          <Highlight
            sectionId={`carousel-${id}`}
            highlight={highlight}
            onSelect={setSelectedHighlight}
            selectedHighlight={selectedHighlight}
            scrolling
            light
          />
        </Grid>
      );
    });
  };

  return (
    <Grid container sx={{ maxWidth: "1000px" }}>
      <Carousel
        content={getHighlightList()}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </Grid>
  );
}
