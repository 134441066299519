import { Grid } from "@mui/material";

import { CONVERSATIONS } from "../config/config";

import ConversationViz from "./ConversationViz";
import ConversationHelpModal from "./ConversationHelpModal";
import SectionHeader from "../../common/banners/SectionHeader";

function ConversationViewContainer({ highlights }) {
  return (
    <Grid container>
      <SectionHeader
        title="Voices By Conversation"
        info={<ConversationHelpModal />}
      />
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ConversationViz
          entities={CONVERSATIONS.data.entities}
          tags={CONVERSATIONS.data.tags}
          highlights={highlights}
        />
      </Grid>
    </Grid>
  );
}

export default ConversationViewContainer;
