import { useState } from "react";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import { ORGANIZATION } from "../../config";
import SingleFadeMenu from "../common/menus/SingleFadeMenu";
import { useIdentityContext } from "react-netlify-identity";

const NavBar = ({ topics }) => {
  const { logoutUser } = useIdentityContext();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      style={{
        background: "#11304B",
      }}
    >
      <Container maxWidth="xxl" style={{ marginLeft: 0 }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
            <IconButton
              size="large"
              aria-label="portal-navigation"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", sm: "none" },
              }}
            >
              {[
                { title: "Home", link: "/" },
                { title: "Insights", link: "/insights" },
                {
                  title: "Voices",
                  link: "/voices",
                },
                { title: "About", link: "/about" },
              ].map(({ title, link }) => (
                <MenuItem
                  key={`mobile-menu-${title}`}
                  onClick={handleCloseNavMenu}
                >
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={link}
                  >
                    {title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              paddingRight: { xs: "0.75rem", sm: "3rem" },
              paddingLeft: ".5rem",
              height: "2.25rem",
            }}
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                alt={ORGANIZATION.name}
                src={ORGANIZATION.logo}
                height="100%"
                style={{ paddingRight: "0.5rem" }}
              />
              Voices of FMCS
            </Link>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
            }}
          >
            <SingleFadeMenu
              key="Insights"
              label="Insights & Recommendations"
              links={topics.map((topic) => {
                return { label: topic.label, link: topic.link };
              })}
              defaultLink="/insights"
              sx={{ color: "white" }}
            />
            <SingleFadeMenu
              key="Voices"
              label="Voices"
              links={[
                { label: "Browse Highlights", link: "voices/search" },
                {
                  label: "Conversation Library",
                  link: "voices/conversation-library",
                },
              ]}
              defaultLink="/voices"
              sx={{ color: "white" }}
            />
            <SingleFadeMenu
              key="About"
              label="About"
              links={[
                { label: "Our Process", link: "about/process" },
                {
                  label: "Our Team",
                  link: "about/team",
                },
              ]}
              defaultLink="/about"
              sx={{ color: "white" }}
            />
          </Box>
          {ORGANIZATION.linkBack && (
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
                paddingRight: ".75rem",
                paddingY: "1.25rem",
              }}
            >
              <Button
                onClick={logoutUser}
                style={{ textDecoration: "none", color: "#FFFFFF" }}
              >
                Logout
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
