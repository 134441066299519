import Partners from "./Partners";
import { useEffect } from "react";

import { STAFF, PARTNERS, ADMIN } from "./config";
import StaffCallout from "./StaffCallout";
import StyledBanner from "../../common/banners/StyledBanner";

function Team() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <StyledBanner
        title={"Our Team"}
        navigation={{ link: "/about", title: "About" }}
        texture
        light
      />
      <StaffCallout config={STAFF} />
      <StaffCallout config={ADMIN} />
      <Partners config={PARTNERS} />
    </div>
  );
}

export default Team;
