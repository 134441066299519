import CardTab from "./CardTab";
import Grid from "@mui/material/Grid";

import ArrowDown from "@mui/icons-material/ArrowDropDown";

function CardTabs({
  items,
  incrementLabel,
  selectedIdx,
  onClick,
  padding,
  small,
}) {
  const itemsPerLine = Math.floor(12 / items.length);
  return (
    <Grid paddingX={padding ? 8 : 0} container sx={{ zIndex: "100" }}>
      <Grid
        container
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          zIndex: "100",
        }}
      >
        {items.map((item, idx) => {
          return (
            <Grid item xs={itemsPerLine} sx={{ height: "100%" }}>
              <CardTab
                idx={idx}
                label={incrementLabel ? `${incrementLabel} ${idx + 1}` : ""}
                body={item.label}
                selectedIdx={selectedIdx}
                onClick={onClick}
                primaryColor={item.primaryColor}
                secondaryColor={item.secondaryColor}
                small={small}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default CardTabs;
