import entities from "../../../assets/content/entities.json";
import CardOne from "../../../assets/voices-page/card_one.png";
import Conversations from "../../../assets/voices-page/conversations.png";
import LandingConversation from "../../../assets/landing-page/conversation_library.png";

export const OVERVIEW = {
  title: "Voices",
  link: "/voices",
  description:
    "Explore the collection of 300+ highlights from FMCS conversations.",
  cards: [
    {
      title: `Browse Highlights`,
      body: "The search tool can be used to explore highlights across conversations.",
      button: "Explore Highlights",
      link: "search",
      picture: CardOne,
    },
    {
      title: "Conversation Library",
      body: "Library of Conversations is a data visualization of collected conversations. Explore the most common themes and listen to connected highlights.",
      button: "Explore Conversations",
      link: "conversation-library",
      picture: LandingConversation,
    },
  ],
};

export const FILTERS = {
  filter_order: ["Tenures", "Topics", "Prompts"],
  data: {
    Topics: [
      {
        key: "tags",
        type: "list",
        match: "work-life balance",
        label: "Work-Life Balance",
      },
      {
        key: "tags",
        type: "list",
        match: "remote working experience",
        label: "Remote Working Experience",
      },
      {
        key: "tags",
        type: "list",
        match: "client engagement",
        label: "Client Engagement",
      },
      { key: "tags", type: "list", match: "culture", label: "Culture" },
      {
        key: "tags",
        type: "list",
        match: "relationships",
        label: "Relationships",
      },
      {
        key: "tags",
        type: "list",
        match: "training + development",
        label: "Training + Development",
      },
      {
        key: "tags",
        type: "list",
        match: "intra-organizational",
        label: "Intra-Organizational",
      },
      { key: "tags", type: "list", match: "process", label: "Process" },
    ],
    Tenures: [
      {
        key: "speaker_demographics.tenure",
        depth: 2,
        type: "string",
        match: "fmcs tenure.0-5 years",
        label: "0-5 years",
      },
      {
        key: "speaker_demographics.tenure",
        depth: 2,
        type: "string",
        match: "fmcs tenure.5-10 years",
        label: "5-10 years",
      },
      {
        key: "speaker_demographics.tenure",
        depth: 2,
        type: "string",
        match: "fmcs tenure.15+ years",
        label: "15+ years",
      },
    ],
    Prompts: [
      {
        key: "tags",
        type: "list",
        match: "*structural codes :: opportunities",
        label: "Hopes",
      },
      {
        key: "tags",
        type: "list",
        match: "*structural codes :: challenges",
        label: "Challenges",
      },
      {
        key: "tags",
        type: "list",
        match: "experiences",
        label: "Personal & Resonating Experiences",
      },
      {
        key: "tags",
        type: "list",
        match: "*structural codes :: fmcs future",
        label: "FMCS Future",
      },
      {
        key: "tags",
        type: "list",
        match: "*structural codes :: takeaways",
        label: "Conversation Takeaways",
      },
    ],
  },
};

export const CONVERSATIONS = {
  data: {
    tags: [
      "work-life balance",
      "remote working experience",
      "client engagement",
      "culture",
      "relationships",
      "training + development",
      "intra-organizational",
      "process",
    ],
    entities: entities,
  },
};
