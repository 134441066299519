import InfoCard from "../common/cards/InfoCard";

export default function TopicCard({
  topicInfo,
  topicId,
  picture,
  teaserHtml,
  small,
  transparent,
  light,
}) {
  // const preview = teaserHtml
  //   ? topicInfo.preview.concat(teaserHtml)
  //   : topicInfo.preview;

  return (
    <InfoCard
      title={topicInfo.label}
      subTitle={topicInfo.subTopics
        .filter((subTopic) => !subTopic.label.includes("Overview"))
        .map((subTopic) => subTopic.label)
        .join(", ")}
      color={topicInfo.primaryColor}
      link={`/insights/${topicId}`}
      linkLabel={"Explore Full Insights & Recommendations"}
      picture={picture}
      small={small}
      preview={teaserHtml}
      transparent={transparent}
      light={light}
    />
  );
}
