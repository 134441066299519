import styled from "styled-components";

const Container = styled.label`
  ${(props) =>
    props.selected
      ? `color: ${props.secondaryColor}; border-bottom:1px solid ${props.secondaryColor};`
      : `color: ${props.primaryColor};`}
  cursor: pointer;
  &:hover {
    ${(props) =>
      props.selected ? "" : `border-bottom:1px solid ${props.primaryColor};`}
`;

function ZagatEmbed({ children, getEmbedInfo }) {
  const splitIndex = children.props.children.lastIndexOf("-");
  const label = children.props.children.slice(0, splitIndex);
  const embedId = children.props.children.slice(splitIndex + 1);

  const {
    selectedEmbedId,
    setSelectedEmbedId,
    embedSection,
    setSelectedEmbedSection,
    primaryColor,
    secondaryColor,
    popUp,
    setOpen,
  } = getEmbedInfo();

  const selected = parseInt(embedId) === parseInt(selectedEmbedId);

  const handleClick = (embedId) => {
    if (popUp) {
      setOpen(embedId);
    } else {
      setSelectedEmbedId(embedId);
      setSelectedEmbedSection(embedSection);
    }
  };

  return (
    <Container
      onClick={() => handleClick(embedId)}
      selected={selected}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      <span style={{ fontWeight: "bold" }}>{label}</span>
    </Container>
  );
}

export default ZagatEmbed;
