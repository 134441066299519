import Team from "../../../assets/about/team.jpg";
import Process from "../../../assets/about/process.jpg";

export const OVERVIEW = {
  title: "About",
  link: "/about",
  description:
    "Learn more about the sensemaking process behind this portal and the team that helped us create it.",
  cards: [
    {
      title: "Our Team",
      body: "This project was done as a collaboration between the FMCS Office of Strategy & Developmen, MIT's Center for Constructive Communication (CCC), and Cortico. See the FMCS mediators who led the way. ",
      button: "View Team",
      link: "team",
      picture: Team,
    },
    {
      title: "Our Process",
      body: "40+ FMCS staff were invited to join six small group conversations. Learn how it all works.",
      button: "Learn More",
      link: "process",
      picture: Process,
    },
  ],
};
