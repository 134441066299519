import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinkButton from "../buttons/LinkButton";

import JsxParser from "react-jsx-parser";

export default function InfoCard({
  title,
  subTitle,
  picture,
  link,
  linkLabel,
  children,
  small,
  preview,
  transparent,
  light,
  sx,
}) {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: transparent ? "unset" : "white",
        minHeight: "100%",
        display: "grid",
        ...sx,
      }}
    >
      {picture && <CardMedia sx={{ paddingTop: "40%" }} image={picture} />}
      <CardContent sx={{ padding: "1.5rem" }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: light ? "white" : "primary.dark",
            paddingBottom: ".5rem",
          }}
        >
          {title}
        </Typography>
        {subTitle && (
          <Typography
            gutterBottom
            variant={small ? "body2" : "body1"}
            component="div"
            color={light ? "white" : "primary.dark"}
            sx={{ fontWeight: "550" }}
          >
            {subTitle}
          </Typography>
        )}
        <Typography
          variant="body2"
          color={light ? "whitesmoke" : "text.secondary"}
          sx={{ paddingTop: "1rem" }}
        >
          {preview ? <JsxParser jsx={preview} /> : children}
        </Typography>
      </CardContent>
      {link && (
        <CardActions sx={{ padding: "1.5rem", verticalAlign: "bottom" }}>
          <LinkButton link={link} size={small ? "small" : "medium"}>
            {linkLabel}
          </LinkButton>
        </CardActions>
      )}
    </Card>
  );
}
