import { Grid } from "@mui/material";

import TopicsOverviewContainer from "./TopicsOverview/TopicsOverviewContainer";
import HighlightOverviewContainer from "./HighlightOverview/HighlightOverviewContainer";

import Callout from "../common/callouts/Callout";
import StyledBanner from "../common/banners/StyledBanner";
import SplitBanner from "../common/banners/SplitBanner";
import InfoCard from "../common/cards/InfoCard";

import { LANDING, HIGHLIGHT, TOPIC } from "./config/config";
import LandingConversation from "../../assets/landing-page/conversation_library.png";
import LandingAbout from "../../assets/landing-page/landing_about.png";
import LandingSplash from "../../assets/landing-page/landing_splash.jpg";

function Landing({ topics }) {
  return (
    <Grid container sx={{ mt: 0 }}>
      <SplitBanner
        title={LANDING.title}
        body={LANDING.description}
        picture={LandingSplash}
        light
        large
        sx={{ width: "90%" }}
      />

      <Grid container item>
        <Callout>
          <TopicsOverviewContainer topics={topics} config={TOPIC} />
        </Callout>
      </Grid>

      <Grid container item xs={12} marginBottom={{ xs: 6, md: 14 }}>
        <HighlightOverviewContainer topics={topics} config={HIGHLIGHT} />
      </Grid>
      <Grid
        container
        sx={{
          margin: "5rem auto",
          width: "80%",
        }}
      >
        <Grid sm={6} item sx={{ display: "flex", justifyContent: "center" }}>
          <InfoCard
            transparent
            picture={LandingConversation}
            title={"Conversation Library"}
            preview="The Conversation Library is a data visualization of collected
                conversations. Explore the most common themes and listen to
                connected highlights."
            sx={{
              maxWidth: "550px",
              backgroundColor: "inherit",
              boxShadow: "none",
              border: "2px solid rgba(0, 0, 0, 0.12) ",
              borderRadius: "10px",
            }}
            link={"/voices/conversation-library"}
            linkLabel={"Visit Conversation Library"}
          />
        </Grid>
        <Grid sm={6} item sx={{ display: "flex", justifyContent: "center" }}>
          <InfoCard
            transparent
            picture={LandingAbout}
            title={"How does this all work?"}
            preview="Learn more about the conversations and how we drew insights from
                what people said."
            sx={{
              maxWidth: "550px",
              backgroundColor: "inherit",
              boxShadow: "none",
              border: "2px solid rgba(0, 0, 0, 0.12) ",
              borderRadius: "10px",
            }}
            link={"/about"}
            linkLabel={"View Our Process"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Landing;
