import Play from "@mui/icons-material/PlayCircleFilled";
import Pause from "@mui/icons-material/PauseCircleFilled";

function PlayButton({ isPlaying, onClick }) {
  const fontStyles = { fontSize: "46px" };
  return isPlaying ? (
    <Pause onClick={onClick} sx={fontStyles} color="primary" />
  ) : (
    <Play onClick={onClick} sx={fontStyles} color="primary" />
  );
}

export default PlayButton;
