import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import HighlightCarousel from "../../common/carousels/HighlightCarousel";
import LinkButton from "../../common/buttons/LinkButton";
import StyledBackground from "../../common/background/StyledBackground";
import DropdownMenu from "../../common/menus/DropdownMenu";
import LandingInsights from "../../../assets/landing-page/landing_insights.png";

function HighlightInfoCompact({
  topics,
  selectedTopic,
  handleChange,
  highlightInfo,
  highlights,
  indexes,
}) {
  return (
    <StyledBackground texture light padding picture={LandingInsights}>
      <Grid>
        <Typography variant="h5" sx={{ color: "white" }}>
          Highlights from Conversations
        </Typography>
      </Grid>
      <Grid xs={12} marginTop={2}>
        <DropdownMenu
          items={topics}
          selected={selectedTopic}
          label="Themes"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} marginTop={5}>
        <Typography
          variant="body2"
          sx={{ paddingBottom: "3rem", color: "inherit" }}
        >
          {highlightInfo.text}
        </Typography>
        <LinkButton link={highlightInfo.search}>Hear Highlights</LinkButton>
      </Grid>
      <Grid item xs={12} marginTop={6}>
        {highlights && (
          <HighlightCarousel
            highlights={highlights}
            indexes={indexes}
            id="compact"
          />
        )}
      </Grid>
    </StyledBackground>
  );
}

export default HighlightInfoCompact;
