import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CardTabs from "../../common/tabs/CardTabs";
import HighlightInfo from "./HighlightInfo";
import HighlightInfoCompact from "./HighlightInfoCompact";
import Highlights from "../../../assets/content/highlights.json";
import StyledBackground from "../../common/background/StyledBackground";
import LandingInsights from "../../../assets/landing-page/landing_insights.png";

function HighlightOverviewContainer({ topics, config }) {
  const tabs = Object.keys(config.featured);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // Need to pass through event
  const handleChangeTab = (label) => {
    setSelectedTab(label);
  };

  const handleChangeDropdown = (event) => {
    setSelectedTab(event.target.value);
  };

  const highlightInfo = config.featured[selectedTab];
  const items = tabs.map((label) => ({ label }));

  const [featured, setFeatured] = useState();
  useEffect(() => {
    setFeatured(
      Highlights.filter((h) => highlightInfo.featured.includes(h.id))
    );
  }, [highlightInfo.featured, selectedTab]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          xs={8}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: "2rem",
            display: { xs: "none", md: "block" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h5"
            sx={{ color: "primary.main" }}
          >
            Conversation Prompts
          </Typography>
          <br />
          <i>
            <Typography
              textAlign="center"
              variant="body1"
              sx={{
                color: "primary.main",
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              “The advent of the pandemic abruptly and forever changed our
              workplace and how we do business. As this new world has evolved,
              FMCS has experienced challenges, and growing pains and made some
              positive discoveries along the way. This transition has been
              marked with different kinds of opportunities and challenges.”
            </Typography>
          </i>
          <br />
          <br />
          <Typography
            textAlign="center"
            variant="body1"
            sx={{ color: "primary.main", fontWeight: "500", fontSize: "20px" }}
          >
            FMCS staff were invited to the conversation through a set of prompts
            to reflect on the changes and the transitions they have experienced
            as a result of the pandemic. We invite you to explore how the
            conversation unfolded through the following questions:
          </Typography>
          <br />
          <br />
        </Grid>
      </Grid>

      <StyledBackground texture light minHeight="auto">
        <div style={{ marginTop: "-5rem", width: "100%" }}>
          <Grid
            item
            xs={12}
            paddingTop={1}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <CardTabs
              items={items}
              selectedIdx={items.findIndex(
                (item) => item.label === selectedTab
              )}
              onClick={(ind) => {
                handleChangeTab(items[ind].label);
              }}
            />
            <HighlightInfo
              highlightInfo={highlightInfo}
              highlights={featured}
              indexes={highlightInfo.featured}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
            <HighlightInfoCompact
              topics={items}
              selectedTopic={selectedTab}
              handleChange={handleChangeDropdown}
              highlightInfo={highlightInfo}
              highlights={featured}
              indexes={highlightInfo.featured}
            />
          </Grid>
        </div>
      </StyledBackground>
    </Grid>
  );
}

export default HighlightOverviewContainer;
