import { useEffect, useRef, useState } from "react";

import * as d3 from "d3";
import {
  combineSnippetsWithSameSpeaker,
  tagToClassName,
} from "./conversationUtils";
import ConversationButtonTags from "./ConversationButtonTags";
import ConversationModal from "./ConversationModal";

function ConversationViz({ entities, tags, highlights }) {
  const [activeButtons, updateActiveButtons] = useState([]);
  const [hoverButtons, updateHoverButtons] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const containerRef = useRef(null);
  const svgRef = useRef(null);

  const svgHeight = 640;
  const svgWidth = 1200;

  const snippetHeight = 9;
  const conversationPadding = 5;
  const verticalSnippetPadding = 1;
  const noOfColumns = 1;
  const conversationWidth = (svgWidth - 5 * noOfColumns) / noOfColumns;

  const textSpaceWidth = 10; // 40 with text

  const [selectedHighlight, setSelectedHighlight] = useState();
  const handleModalClose = () => {
    setSelectedHighlight(undefined);
  };

  useEffect(() => {
    const Tooltip = d3
      .select(containerRef.current)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    // Highlight snippet and show tooltip when mouse over snippet (only highlights)
    const mouseoverSnippet = (event, d) => {
      if (d["highlight_words"]) {
        d3.select("#id" + d.id).classed("hover", true);
        Tooltip.transition().duration(200).style("opacity", 0.9);
        Tooltip.html(
          `${d["highlight_words"].split(" ").slice(0, 20).join(" ") + "..."}`
        )
          .style("left", event.pageX + "px")
          .style("top", event.pageY + "px")
          .style("max-width", svgWidth - event.pageX + "px");
      }
    };

    // Remove highlight and hide tooltip when mouse leaves snippet
    const mouseleaveSnippet = (event, d) => {
      d3.select("#id" + d.id).classed("hover", false);
      if (d["highlight_words"]) {
        Tooltip.transition().duration(200).style("opacity", 0);
      }
    };

    // Snippet click callback, prepare modal with the correct embed and
    // shows it to the user.
    const snippetClick = (event, d) => {
      if (d["highlight_words"]) {
        setSelectedHighlight(
          highlights.find((highlight) => highlight.id === d.highlight_id)
        );
      }
    };

    const svg = d3.select(svgRef.current);
    svg.selectAll("g").remove();
    const g = svg.append("g").attr("transform", "translate(0,0)");

    // Variables determining where each conversation starts drawing
    // these will be updated after each conversation have been drawn to
    // prepare for the next one.
    let startX = 5;
    let startY = 5;

    // Process conversation, pulling out duration, speaker count and
    // combining snippets with same speaker for a more clean viz.
    const processConversation = (conversationId, entities) => {
      const conversationDuration =
        entities.conversations[conversationId].duration;
      const speakerCount = Object.keys(
        entities.conversations[conversationId].speech_pipeline_info
          .alignment_info.speaker_stats
      ).length;

      const convHeight =
        snippetHeight * speakerCount +
        2 * conversationPadding +
        verticalSnippetPadding * (speakerCount - 1);
      let nextStartY = startY + convHeight + 10;
      if (nextStartY > svgHeight - 30) {
        startY = 10;
        nextStartY = 10 + convHeight + 10;
        startX += conversationWidth + 10;
      }

      const combinedSnippets = combineSnippetsWithSameSpeaker(entities);

      drawConversation({
        x: startX,
        y: startY,
        width: conversationWidth,
        snippets: combinedSnippets,
        duration: conversationDuration,
        speakerCount,
        conversationId,
      });

      startY = nextStartY;
    };

    // Draw one conversation including backgrounds, snippets and text.
    const drawConversation = ({
      x,
      y,
      width,
      snippets,
      duration,
      speakerCount,
      conversationId,
    }) => {
      const gConversation = g
        .append("g")
        .attr("class", "conv_g_" + conversationId);

      // Draw background rectangle used when zooming in on a
      // conversation to allow the user to click anywhere to
      // zoom back out
      gConversation
        .append("rect")
        .data([conversationId])
        .attr("class", "conv_background_" + conversationId)
        .attr("x", -20)
        .attr("y", 0)
        .attr("width", windowWidth)
        .attr("height", svgHeight)
        .attr("fill", function (d, i) {
          return "rgba(0, 0, 0, 0.4)";
        })
        .style("opacity", 0)
        .style("display", "none")
        .on("click", clickToZoom);

      // Calculate the height of the conversation based on the
      // amount of speakers, includes padding between and around
      // each speaker row
      const convHeight =
        snippetHeight * speakerCount +
        2 * conversationPadding +
        verticalSnippetPadding * (speakerCount - 1);

      // Append conversation rectangle (background)
      gConversation
        .append("rect")
        .data([conversationId])
        .attr("x", x + textSpaceWidth)
        .attr("y", y)
        .attr("width", width - textSpaceWidth)
        .attr("height", convHeight)
        .attr("fill", function (d, i) {
          return "#F8F8F8";
        })
        .on("click", clickToZoom);

      // Create scale from duration to rectangle width minus spacing
      const scale = d3
        .scaleLinear()
        .domain([0, duration])
        .range([0, width - 2 * conversationPadding - textSpaceWidth]);

      const speakersObj = {};
      // Append snippet rectangles
      gConversation
        .append("g")
        .selectAll("snippet")
        .data(snippets)
        .enter()
        .append("rect")
        .attr("id", (snippet) => {
          return "id" + snippet["id"];
        })
        .attr("class", (snippet) => {
          if (!speakersObj[snippet.speaker_id]) {
            // Save speakers names for later text
            speakersObj[snippet.speaker_id] = snippet.speaker_name;
          }

          // Add each tag as a class for later highlighting etc
          let tags = "";
          if (snippet.tags) {
            tags += "hasTag ";
            snippet.tags.forEach((tag) => {
              const mainAndSubTag = tag.split(" :: ");
              tags += tagToClassName(mainAndSubTag[0]) + " ";
            });
          }
          return "snippet " + tags;
        })
        .attr(
          "x",
          (snippet) =>
            x +
            textSpaceWidth +
            conversationPadding +
            scale(snippet["audio_start_offset"])
        )
        .attr(
          "y",
          (snippet) =>
            y +
            conversationPadding +
            (snippetHeight + verticalSnippetPadding) *
              Number(snippet.speaker_id)
        )
        .attr("width", (snippet) => scale(snippet["duration"]))
        .attr("height", snippetHeight)
        .attr("fill", function (d, i) {
          return "#d0d4d4";
        })
        .on("mouseover", mouseoverSnippet)
        .on("mouseleave", mouseleaveSnippet)
        .on("click", snippetClick);

      // Add speakers to array in order for drawing
      const speakers = [];
      for (let i = 0; i < speakerCount; i++) {
        speakers.push(speakersObj[i]);
      }
      // Draw speakers hidden by default only showing once zoomed in
      // on that conversation
      gConversation
        .append("g")
        .attr("class", () => "conv_id" + conversationId + " speaker_text")
        .style("opacity", 0)
        .selectAll("speakers")
        .data(speakers)
        .enter()
        .append("text")
        .attr("x", x + textSpaceWidth - 5)
        .attr(
          "y",
          (speaker, i) =>
            y +
            conversationPadding +
            (snippetHeight + verticalSnippetPadding) * Number(i)
        )
        .attr("dy", snippetHeight / 2)
        .attr("alignment-baseline", "middle")
        .attr("text-anchor", "end")
        .style("font-size", "7px")
        .style("fill", "#F8F8F8")
        .text((d) => {
          return d;
        });
    };

    const link = d3
      .linkHorizontal()
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      });

    // Draw line connection from all active (pushed) tag buttons and
    // any hovered tag button to snippets with those tags.
    const drawConnections = () => {
      if (activeButtons.length === 0 && hoverButtons.length === 0) return;
      let connectionClass = "";
      const buttonsToConnect = activeButtons.concat(hoverButtons);
      buttonsToConnect.forEach((id) => {
        connectionClass += "." + id;
      });
      d3.selectAll(".line").remove();
      if (connectionClass) {
        d3.selectAll(connectionClass + ".snippet").each(function (d, i) {
          let x = Number(d3.select(this).attr("x"));
          let y = Number(d3.select(this).attr("y"));
          let dx = Number(d3.select(this).attr("width")) / 2;
          let dy = Number(d3.select(this).attr("height")) / 2;

          buttonsToConnect.forEach((id) => {
            let indexOfTag = tags.findIndex(
              (tag) => tagToClassName(tag) === id
            );
            let tagWidth = svgWidth / tags.length;

            let data = {
              source: {
                x: tagWidth * indexOfTag + tagWidth / 2,
                y: svgHeight,
              },
              target: {
                x: x + dx,
                y: y + dy,
              },
            };

            g.append("path")
              .attr("d", link(data))
              .attr("class", "line " + id)
              .style("stroke-width", 0.4)
              .attr("fill", "none");
          });
        });
      }
    };

    // Highlight all active snippets via full opacity vs 0.5 for the rest
    const highlightSnippets = () => {
      d3.selectAll(".snippet").style("opacity", 0.5);
      let connectionClass = ".snippet";
      activeButtons.forEach((id) => {
        connectionClass += "." + id;
      });
      if (activeButtons.length > 0) {
        d3.selectAll(connectionClass)
          .style("opacity", 1)
          .classed("highlight", true);
      }
      if (activeButtons.length === 0) {
        d3.selectAll(".snippet").style("opacity", 1);
      }
    };

    // Enable and highlight tag buttons that have snippets with tags of
    // the active buttons + extra.
    // Basically disables tag buttons that doesn't share snippets with
    // active tags.
    const highlightComboButtons = () => {
      if (!activeButtons.length) {
        d3.selectAll(".tag-button").property("disabled", false);
        d3.selectAll(".tag-button").classed("disabled", false);
        return;
      }
      let connectionClass = "";
      activeButtons.forEach((id) => {
        connectionClass += "." + id;
      });
      d3.selectAll(".tag-button").classed("disabled", true);
      d3.selectAll(".tag-button").property("disabled", true);
      tags.forEach((tag) => {
        if (
          activeButtons.includes(tagToClassName(tag)) ||
          !d3.select(connectionClass + "." + tagToClassName(tag)).empty()
        ) {
          d3.select("#" + tagToClassName(tag)).classed("disabled", false);
          d3.select("#" + tagToClassName(tag)).property("disabled", false);
        }
      });
    };

    //Zoom
    let zoomed = false;

    const clickToZoom = (event, d) => {
      let x, y, k;

      if (zoomed) {
        x = svgWidth / 2;
        y = svgHeight / 2;
        k = 1;
      } else {
        const bbox = event.target.getBBox();
        x = bbox.x + bbox.width / 2;
        y = bbox.y + bbox.height / 2;
        k = 2;
      }

      g.transition()
        .duration(750)
        .attr(
          "transform",
          "translate(" +
            svgWidth / 2 +
            "," +
            svgHeight / 2 +
            ")scale(" +
            k +
            ")translate(" +
            -x +
            "," +
            -y +
            ")"
        );
      if (zoomed) {
        d3.selectAll(".speaker_text")
          .transition()
          .duration(750)
          .style("opacity", 0);
        d3.select(".conv_background_" + d)
          .transition()
          .duration(750)
          .style("opacity", 0)
          .on("end", () => {
            d3.select(".conv_background_" + d).style("display", "none");
          });
      } else {
        d3.selectAll(".conv_id" + d)
          .transition()
          .duration(750)
          .style("opacity", 1);
        d3.select(".conv_g_" + d).raise();
        d3.select(".conv_background_" + d)
          .style("display", "")
          .transition()
          .duration(750)
          .style("opacity", 1);
      }

      zoomed = !zoomed;
    };

    // Process data and draw viz
    Object.entries(entities).forEach(([conversationId, entities]) => {
      processConversation(conversationId, entities);
    });

    drawConnections();
    highlightSnippets();
    highlightComboButtons();
  }, [activeButtons, windowWidth]);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    }

    return () => window.removeEventListener("resize", null);
  }, []);

  const drawConnections = (activeButtons, hoverButtons) => {
    updateActiveButtons((arr) => [...activeButtons]);
    updateHoverButtons((arr) => [...hoverButtons]);
  };

  return (
    <div ref={containerRef} className="conversation-viz">
      <svg ref={svgRef} width={svgWidth} height={svgHeight} />
      <ConversationButtonTags
        drawConnections={drawConnections}
        tags={tags}
      ></ConversationButtonTags>
      <ConversationModal
        onClose={handleModalClose}
        highlight={selectedHighlight}
      ></ConversationModal>
    </div>
  );
}

export default ConversationViz;
