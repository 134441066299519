import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import HighlightCarousel from "../../common/carousels/HighlightCarousel";
import LinkButton from "../../common/buttons/LinkButton";

function HighlightInfo({ highlightInfo, highlights, indexes }) {
  return (
    <>
      {/* <Grid item md={4} sx={{ marginTop: "5rem" }} paddingLeft={8}>
        <Typography
          variant="body2"
          sx={{ paddingBottom: "3rem", color: "inherit" }}
        >
          {highlightInfo.text}
        </Typography>
        <LinkButton link={highlightInfo.search}>Hear Highlights</LinkButton>
      </Grid> */}
      <Grid item md={2} />
      <Grid item md={8} sx={{ marginTop: "3rem", marginX: "auto" }}>
        {highlights && (
          <HighlightCarousel
            highlights={highlights}
            indexes={indexes}
            id="normal"
          />
        )}
        <div style={{ marginLeft: "1.5rem", marginTop: "2rem" }}>
          <LinkButton link={highlightInfo.search}>Hear Highlights</LinkButton>
        </div>
      </Grid>
      <Grid item md={2} />
    </>
  );
}

export default HighlightInfo;
